:root {
    --primary-color: rgb(1, 37, 44);
    --secondary-color: rgb(136, 223, 149);
    --tertiary-color: rgb(254, 95, 85);
    --surface-color: white;
}

html,
body {
    margin: 0;
    padding: 10px;
    height: 100%;
    background-color: var(--primary-color);
}

.charge-point-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 70vh;
}

.logo-container {
    margin-bottom: 20px;
}

.logo {
    height: 50px;
}

.content-container {
    display: flex;
    justify-content: center;
    width: 80%;
    align-items: center;
    background-color: var(--surface-color);
    border-radius: 20px;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    margin: 20px ;
    padding: 20px;
    min-height: 30vh;
    min-width: 30vh;
    max-width: max-content;
}

.map-container {
    flex: 1;
}

.details-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.details {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.detail {
    display: flex;
    justify-content: space-between;
}

.label {
    color: var(--primary-color);
}

.value {
    color: var(--primary-color);
    font-weight: bold;
    text-align: right;
}

.button-container {
    display: flex;
    justify-content: center;
    align-items: center; 
    margin: 10px; 
    font-size: 15px;
    font-weight: bold;
    padding: 5px;
    border-radius: 15px;
    border: 2px solid var(--tertiary-color);
    text-align: center;
}

.app-store-links {
    margin: 20px; 
}

.loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.error-container {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    color: var(--tertiary-color);
}

.centered-div {
    display: flex;
    flex-direction: column; 
    justify-content: center;
    align-items: center;
    height: 80vh;
    text-align: center;
    font-weight: bold;
    color: var(--tertiary-color);
    background-color: var(--primary-color);
}

.centered-div img {
    margin: 20px;
    max-width: 100%; 
}